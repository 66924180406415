import React, { useState } from 'react'
import Scrollspy from 'react-scrollspy'
import Link from 'next/link';
import LazyLoad from 'react-lazy-load'
import { useRouter } from 'next/router';
// import { Link, useHistory } from 'react-router-dom'
import { preAuthPaths, postAuthPaths } from '../../config/constants/NavigationConstants'
import { CustomModal, CustomHeader, CustomButton } from '../../components/commons'
import styles from './Home.module.css';

const Home = ({isLoggedIn, ...props}) => {
    const history = useRouter();
    const [activePage, setActivePage] = useState('section-1');
    const modalRefVidoe1 = React.useRef();
    const modalRefVidoe2 = React.useRef();
    const [showModel, setModel] = useState(false);
    const [showAutoPlayEnable, setAutoPlayEnable] = useState(0);
    const hideModal = () => {
        //window.$(modalRef.current).modal('hide');
        setModel(false);
        history.push(
            isLoggedIn ? `${postAuthPaths.FOREST}?switcherState=RIGHT` : preAuthPaths.FOREST
        );
    }

    const playPauseVideo = (e) => {
        const player = e.current;
        const command = {
            "event": "command",
            "func": "pauseVideo"
        };
        player.contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
        // console.log("Inside playPauseVideo Event-->", e.current);
        // console.log("Inside playPauseVideo-->", e.current.chil);
    }

    return (
        <>
            {/* <CustomHeader /> */}
            <CustomModal
                show={ showModel }
                modalTitle=''
                showModalFooter={ false }
                onClose={() => setModel(false)}
            >
                <video controls autoPlay muted loop>
                    <source src="./images/banner-video.mp4" type="video/mp4" />
                </video>
                <div className='choose-your-tree'>
                    <CustomButton name="CHOOSE YOUR TREES" onClick={() => hideModal()} />
                </div>
            </CustomModal>
            <div id="dots">
                <Scrollspy items={ ['section-1', 'section-2', 'section-3', 'section-4', 'section-5', 'section-6'] } currentClassName="active">
                    <li><a onClick={ () => setActivePage('section-1') } href="#section-1" className={ `js-anchor-link ${ activePage === 'section-1' ? 'active': '' }` }> </a></li>
                    <li><a onClick={ () => setActivePage('section-2') } href="#section-2" className={ `js-anchor-link ${ activePage === 'section-2' ? 'active': '' }` }> </a></li>
                    <li><a onClick={ () => setActivePage('section-3') } href="#section-3" className={ `js-anchor-link ${ activePage === 'section-3' ? 'active': '' }` }> </a></li>
                    <li><a onClick={ () => setActivePage('section-4') } href="#section-4" className={ `js-anchor-link ${ activePage === 'section-4' ? 'active': '' }` }> </a></li>
                    <li><a onClick={ () => setActivePage('section-5') } href="#section-5" className={ `js-anchor-link section-5 ${ activePage === 'section-5' ? 'active': '' }` }> </a></li>
                    <li><a onClick={ () => setActivePage('section-6') } href="#section-6" className={ `js-anchor-link ${ activePage === 'section-6' ? 'active': '' }` }> </a></li>
                </Scrollspy>
            </div>

            <div id="section-1" className="section">
                <div className="banner-img" />
                <LazyLoad>
                    <video autoPlay muted loop id="myVideo" preload="none" poster="./images/11.png" className={styles.responsiveVideo}>
                        <source src="./images/banner-video.mp4" type="video/mp4" />
                    </video>
                </LazyLoad>

                <div className="banner-wrap">
                    <div className="container-wrap">
                        <div className="cmn-head">
                            <h2><span className="blur p-2">Welcome to GiftTrees</span></h2>
                        </div>
                        <p className="get-plant"><span className="blur p-2">Get planting today</span></p>
                       
                        <a className="store-btn" target="_blank" href={"https://gifttrees.store/collections/gifttrees?utm_source=referral&utm_medium=cta&utm_campaign=gifttrees_main_site_referral&utm_id=gifttrees_main_site&utm_term=home_page_main_cta_buy_gifttrees&utm_content=home_page_main_cta_buy_gifttrees"}>
                            <img alt="Not Found" src="images/cta-store.svg" className="img-fluid" />
                        </a>
                        <p className="choose-trees-link"><span className="blur p-2">Got a GiftTree already? <a href={isLoggedIn ? `${postAuthPaths.FOREST}?switcherState=RIGHT` : preAuthPaths.FOREST}><span style={{textDecoration: 'underline'}}>Choose your tree</span></a></span></p>
                    </div>
                </div>
                <div className="nxt-arw">
                    <a onClick={ () => setActivePage('section-2') } href="#section-2" className="js-anchor-link">
                        <img alt="Not found"  src="./images/arrow.png" />
                    </a>
                </div>
            </div>

            <div id="section-2" className="section">
                <div className="detail-wrap">
                    <div className="detail">
                        <h4>Start changing lives by building your GiftTree forest today.</h4>
                        <p>
                           Received a GiftTree? Simply select where in the world you would like to plant your GiftTree and we will send you a certificate of planting.
                        </p>
                        <p>Through our partners and your efforts we are:</p>
                        <ul>
                            <li>Reducing CO<sub>2</sub> emissions</li>
                            <li>Repairing ecosystems</li>
                            <li>Creating food, health & education opportunities</li>
                            <li>Reducing carbon footprints</li>
                        </ul>
                        <span 
                        style={{cursor: "pointer"}}
                        onClick={ () => history.push(
                                isLoggedIn ? `${postAuthPaths.FOREST}?switcherState=RIGHT` : preAuthPaths.FOREST
                            )} 
                            >
                                Plant your GiftTree here today.
                                </span>
                        {/* <span>Keep track of how many trees you and other members of the GiftTrees community are planting.</span> */}
                    </div>
                </div>
                <div className="nxt-arw">
                    <a onClick={ () => setActivePage('section-3') } href="#section-3" className="js-anchor-link">
                        <img alt="Not found"  src="./images/arrow.png" />
                    </a>
                </div>
            </div>

            <div id="section-3" className="section">
                <div className="plantingTrees-wrap">
                    <div className="container-wrap">
                        <h3>Now That You Have Your GiftTree...</h3>
                        <ul>
                            <li>
                                <span>1</span>
                                <img alt="Not found"  src="./images/location.png" className="img-fluid" />
                                <p>Look on the map</p>
                            </li>
                            <li>
                                <span>2</span>
                                <img alt="Not found"  src="./images/planting.png" className="img-fluid" />
                                <p>Choose your tree</p>
                            </li>
                            <li>
                                <span>3</span>
                                <img alt="Not found"  src="./images/forest.png" className="img-fluid" />
                                <p>Build your forest</p>
                            </li>
                        </ul>
                        <p>Get started by viewing our <span>map of available trees...</span></p>
                        <button 
                            onClick={ () => history.push(
                                    isLoggedIn ? `${postAuthPaths.FOREST}?switcherState=RIGHT` : preAuthPaths.FOREST
                            )}  
                            type="button" 
                            className="cmn-btn"
                        >
                            CHOOSE YOUR TREE
                        </button>
                    </div>
                </div>
                <div className="nxt-arw">
                    <a onClick={ () => setActivePage('section-4') } href="#section-4" className="js-anchor-link">
                        <img alt="Not found"  src="./images/arrow.png" />
                    </a>
                </div>
            </div>

            <div id="section-4" className="section">
                <div className="subHd-wrap">
                    <div className="container-wrap">
                        <h1>1,600,000</h1>
                        <p>trees already in 17 different developing countries improving education, biodiversity, CO2
                            and...</p>
                    </div>
                </div>
                <div className="nxt-arw">
                    <a onClick={ () => setActivePage('section-5') } href="#section-5" className="js-anchor-link">
                        <img alt="Not found"  src="./images/arrow.png" />
                    </a>
                </div>
            </div>

            <div id="section-5" className="section">
                <div className="set-tree-wrap">
                    <div className="container-wrap text-white text-center heading-text-forest-section">
                        <p className="heading">10 Reasons To Use GiftTrees</p>
                        <p className="font-weight-bold">Hover over a tree to read more</p>
                    </div>
                    <span className="tree-1">
                        <div style={ {position: 'relative'} }>
                            <img alt="Not found"  src="./images/tree1.svg" />
                        </div>
                    </span>
                    <span className="tree-2">
                        <div style={ {position: 'relative'} }>
                            <img alt="Not found"  src="./images/tree2.svg" />
                            <button type="button">Supporting poor communities</button>
                        </div>
                    </span>
                    <span className="tree-3">
                        <div style={ {position: 'relative'} }>
                            <img alt="Not found"  src="./images/tree3.svg" />
                            <button type="button">Offsetting your impact</button>
                        </div>
                    </span>
                    <span className="tree-4">
                        <div style={ {position: 'relative'} }>
                            <img alt="Not found"  src="./images/tree4.svg" />
                        </div>
                    </span>
                    <span className="tree-5">
                        <div style={ {position: 'relative'} }>
                            <img alt="Not found"  src="./images/tree5.svg" />
                            <button type="button">Just £1.23 per tree</button>
                        </div>
                    </span>
                    <span className="tree-6">
                        <div style={ {position: 'relative'} }>
                            <img alt="Not found"  src="./images/tree6.svg" />
                            <button type="button">A great gift</button>
                        </div>
                    </span>
                    <span className="tree-7">
                        <div style={ {position: 'relative'} }>
                            <img alt="Not found"  src="./images/tree7.svg" />
                        </div>
                    </span>
                    <span className="tree-8">
                        <div style={ {position: 'relative'} }>
                            <img alt="Not found"  src="./images/tree8.svg" />
                            <button type="button">Choice of locations</button>
                        </div>
                    </span>
                    <span className="tree-9">
                        <div style={ {position: 'relative'} }>
                            <img alt="Not found"  src="./images/tree9.svg" />
                            <button type="button">Choice of trees</button>
                        </div>
                    </span>
                    <span className="tree-10">
                        <div style={ {position: 'relative'} }>
                            <img alt="Not found"  src="./images/tree10.svg" />
                            <button type="button">Improving the planet for all</button>
                        </div>
                    </span>
                    <span className="tree-11">
                        <div style={ {position: 'relative'} }>
                            <img alt="Not found"  src="./images/tree11.svg" />
                            <button type="button">Indirectly providing education</button>
                        </div>
                    </span>
                    <span className="tree-12">
                        <div style={ {position: 'relative'} }>
                            <img alt="Not found"  src="./images/tree12.svg" />
                            <button type="button">Providing work in developing nations</button>
                        </div>
                    </span>
                    <span className="tree-13">
                        <div style={ {position: 'relative'} }>
                            <img alt="Not found"  src="./images/tree13.svg" />
                            <button type="button">Creating food supplies</button>
                        </div>
                    </span>
                    <span className="tree-14">
                        <div style={ {position: 'relative'} }>
                            <img alt="Not found"  src="./images/tree14.svg" />
                        </div>
                    </span>
                </div>
                <div className="d-block d-sm-block d-md-none">
                    <div className="head-box">
                        <h6>Hover &amp; click a tree to find out more about the benefits of planting trees.</h6>
                        <div className="head-box-arrows">
                            <i className="fas fa-angle-left"/>
                            <i className="fas fa-angle-right"/>
                        </div>
                    </div>
                </div>
                <div className="nxt-arw">
                    <a onClick={ () => setActivePage('section-6') } href="#section-6" className="js-anchor-link">
                        <img alt="Not found"  src="./images/arrow.png" />
                    </a>
                </div>
            </div>

            <div id="section-6" className="section">
                <div className="videoSlider-wrap">
                    <div className="container-wrap">
                        <h3>Videos</h3>
                        <div id="demo" className="carousel slide" data-interval={null}>
                        <LazyLoad>
                            <div className="carousel-inner">
                                <div className="carousel-item active" onChange={(e) => console.log('video changed')}>
                                    <iframe width="550" height="315" 
                                        src={`https://www.youtube.com/embed/WIJi2YVPmu4?enablejsapi=1&version=3&autoplay=${showAutoPlayEnable}`} 
                                        title="GiftTrees - Planting Trees in Tanzania" 
                                        frameBorder="0" 
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                        allowFullscreen="allowFullScreen" 
                                        ref={modalRefVidoe1}
                                        id ="video1"
                                    />
                                </div>
                                <div className="carousel-item" onChange={(e) => console.log('video changed')}>
                                    <iframe width="550" height="315" 
                                        src={`https://www.youtube.com/embed/Lq1tiXeS3MU?enablejsapi=1&version=3&autoplay=${showAutoPlayEnable}`} 
                                        title="GiftTrees - Our Founder's Story" 
                                        frameBorder="0" 
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                        allowFullscreen="allowFullScreen" 
                                        ref={modalRefVidoe2}
                                        id ="video2"
                                    />
                                </div>
                            </div>
                            </LazyLoad>
                            <ul className="carousel-indicators">
                                <li data-target="#demo" data-slide-to="0" className="active" onClick={() => playPauseVideo(modalRefVidoe2)}/>
                                <li data-target="#demo" data-slide-to="1" onClick={() => playPauseVideo(modalRefVidoe1)}/>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="nxt-arw">
                    <a onClick={ () => setActivePage('section-1') } href="#section-1" className="js-anchor-link">
                        <img alt="Not found" className="arrow-up"  src="./images/arrow.png" />
                    </a>
                </div>
            </div>
            <footer>
                <div className="container-wrap">
                    <div className="footer-top">
                        <div className="footer-menu">
                            <div className="footer-logo">
                                <figure className="mb-0 img-logo">
                                    <Link href={ preAuthPaths.HOME }>
                                        <img src="/images/logo.png" className="img-fluid" alt="Not found" />
                                    </Link>
                                </figure>
                            </div>
                            {!isLoggedIn && 
                                <ul>
                                    <li>
                                        <Link href={ preAuthPaths.HOME }>
                                            HOME
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href={ isLoggedIn ? postAuthPaths.PORTAL : preAuthPaths.LOGIN }>
                                            {isLoggedIn ? 'PORTAL' : 'LOG IN' }
                                        </Link>
                                    </li>
                                    <li style={{whiteSpace: 'nowrap'}}>
                                        <Link href={ isLoggedIn ? postAuthPaths.ABOUT : preAuthPaths.SIGNUP }>
                                            {isLoggedIn ? 'ABOUT' : 'SIGN UP'}
                                        </Link>
                                    </li>
                                </ul>
                            }
                        </div>

                        <div className={isLoggedIn ? 'footer-button footer-button-login' : 'footer-button footer-button-logout' }>
                            <div className={styles.footerLinkContainer}>
                                <div className={styles.footerLinkItem}>
                                    <span className={styles.footerLinkText}>
                                        <a href="mailto:Hello@gifttrees.com">
                                            Hello@gifttrees.com
                                        </a>
                                    </span>
                                    <span className={styles.footerLinkText}>
                                        <a href="tel:+4402089383658">
                                            Tel: 020 8938 3658
                                        </a>
                                    </span>
                                </div>

                                <div>
                                <a className="store-btn ml-md-4" target="_blank" href={"https://gifttrees.store/collections/gifttrees?utm_source=referral&utm_medium=cta&utm_campaign=gifttrees_main_site_referral&utm_id=gifttrees_main_site&utm_term=footer_cta_buy_gifttrees&utm_content=footer_cta_buy_gifttrees"}>
                                    <img alt="Not Found" src="images/cta-store.svg" className="img-fluid" />
                                </a>
                                </div>
                            </div>

                            <div className="footer-bottom">
                                <div className={styles.privacyContainer}>
                                    <div>
                                        <span>
                                            <a href="https://www.sustainably.run/privacy-policy" target="_blank">
                                                Privacy Policy
                                            </a>
                                        </span>
                                    </div>
                                    <div className={styles.separator}/>
                                    <div>
                                        <span>GiftTrees is a brand created by GiftTrees Partners Limited (Reg No. 08887943)</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                    </div>

                    <div className="footer-bottom">
                        {/* <div className={styles.privacyContainer}>
                            <div>
                                <span>Privacy Policy</span>
                            </div>
                            <div className={styles.separator}/>
                            <div>
                                <span>GiftTrees is a brand created by GiftTrees Partners Limited (Reg No. 08887943)</span>
                            </div>
                        </div> */}
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Home;